import Vue from "vue";
import Vuetify from "vuetify/lib";
import i18n from "@/i18n";
import minifyTheme from "minify-css-string";

Vue.use(Vuetify);

export default new Vuetify({
  lang: {
    t: (key, ...params) => i18n.t(key, params),
  },

  theme: {
    themes: {
      light: {
        primary: "#009491",
        secondary: "#154747",
        accent: "#2196f3",
        error: "#f44336",
        warning: "#ff9800",
        info: "#0094FF",
        success: "#4caf50",
        background: "#4caf50",
        subtitle: "#757575",
        defaultPrimary: "#009491",
        defaultSecondary: "#154747",
      },
      dark: {
        primary: "#1976D2",
        secondary: "#424242",
        accent: "#82B1FF",
        subtitle: "#FAFAFA",
        defaultPrimary: "#1976D2",
        defaultSecondary: "#424242",
      },
    },
    options: {
      minifyTheme,
      customProperties: true,
    },
  },
});
