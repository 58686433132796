<template>
  <router-view/>
</template>

<script>
export default {
  name: "App",
};
</script>

<style>

h1,
h2,
h3,
h4 {
  font-family: "Nunito Sans", sans-serif !important;
}

/* EXTREMELY IMPORTANT!! EVERYTHING RELIES ON THIS GODFORSAKEN FREAK OF NATURE */
.row:not([class*="my-"]):not([class*="ma-"]):not([class*="mt-"]):not([class*="mb-"]) {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

/* EXTREMELY IMPORTANT!! EVERYTHING RELIES ON THIS GODFORSAKEN FREAK OF NATURE */

/* Webkit Scrollbar Stuff */
::-webkit-scrollbar {
  width: 10px !important;
}

::-webkit-scrollbar-track {
  background: #f3f3f3 !important;
}

::-webkit-scrollbar-thumb {
  background: #888 !important;
  border-radius: 8px !important;
}

::-webkit-scrollbar-thumb:hover {
  background: #777 !important;
}
</style>
