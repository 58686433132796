export const MAIN = {
  LICENSEE: {
    GENERAL: "General",
    BRANDING: "Branding",
    BILLING: "Billing",
    SECURITY: "Security",
    TABS: ["General", "Branding", "Billing", "Security"],
    USER_SETTINGS: {
      PASSWORD_POLICY: {
        STRONG: 16,
        WEAK: 12,
      },
      SECONDARY_EMAIL_POLICY: {
        NOT_USED: "not_used",
        REQUIRED: "required",
        OPTIONAL: "optional",
      },
    },
  },

  TERMS_AND_CONDITIONS: {
    PRIVACY_POLICY_DEVAPP:
      "f849f1e0-45ec-11ec-8188-adcfd2fbf2b5/Privacy_policy_V211110.pdf",
    PRIVACY_POLICY_PRODUCTION:
      "1a7cdd50-45ed-11ec-be7e-6ba78caa397f/Privacy_policy_V211110.pdf",
    TERMS_OF_USE_DEVAPP:
      "f72184a0-45ec-11ec-bfa3-ffd9abfdb8b3/Term_of_Use_TheRoll_V002.pdf",
    TERMS_OF_USE_PRODUCTION:
      "1a5f94e0-45ed-11ec-abab-93537e480cbb/Term_of_Use_TheRoll_V002.pdf",
  },

  VIEWPORT_SIZES: {
    LARGE_MOBILE: 960,
    NAV_DRAW_MIN_SIZE: 600,
  },

  FILE_INPUT_SIZE: {
    MAXIMUM_FILE_SIZE: 10000000,
  },

  SOCIAL_ICON_LINKS: [
    { icon: "mdi-instagram", link: "https://www.instagram.com/therollab/" },
    {
      icon: "mdi-linkedin",
      link: "https://www.linkedin.com/company/the-roll/",
    },
    { icon: "mdi-facebook", link: "https://www.facebook.com/therollab/" },
  ],

  ROLES: {
    COACH: "coach",
  },

  TIPTAPEDITOR_LIMITS: {
    BIO: 10000,
    SHORT_BIO: 300,
    PROGRAM_DESCRIPTION: 10000,
    NOTE: 5000,
    MEMO: 5000,
  },
};
