import Vue from "vue";
import Router from "vue-router";
import store from "./store";
import cookies from "vue-cookies";

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      name: "Home",
      path: "/",
      component: () => import("@/views/Index"),
      children: [
        // Dashboard
        {
          name: "Dashboard",
          path: "",
          component: () => import("@/views/Dashboard"),
          meta: {
            requiresAuth: true,
            title: `${process.env.VUE_APP_NAME} | Dashboard`,
          },
        },
        // Pages
        /*         {
                          name: "Resources",
                          path: "resources",
                          component: () => import("@/views/pages/resources/Resources"),
                          meta: { requiresAuth: true },
                        }, */
        {
          name: "Resources",
          path: "resources/:id?",
          component: () => import("@/views/pages/resources/Resources"),
          meta: {
            requiresAuth: true,
            title: `${process.env.VUE_APP_NAME} | Resources`,
          },
        },
        {
          name: "Profile",
          path: "profile/:id",
          component: () => import("@/views/pages/users/Profile"),
          meta: {
            requiresAuth: true,
            title: `${process.env.VUE_APP_NAME} | Profile`,
          },
        },
        {
          name: "OwnProfile",
          path: "profile/",
          component: () => import("@/views/pages/users/Profile"),
          meta: {
            requiresAuth: true,
            title: `${process.env.VUE_APP_NAME} | Profile`,
          },
        },
        {
          name: "Reminders",
          path: "reminders",
          component: () => import("@/views/pages/Reminder"),
          meta: {
            requiresAuth: true,
            title: `${process.env.VUE_APP_NAME} | Reminders`,
          },
        },
        {
          name: "Programs",
          path: "programs/:id?",
          component: () => import("@/views/pages/programs/Programs"),
          meta: {
            requiresAuth: true,
            title: `${process.env.VUE_APP_NAME} | Programs`,
            allowedRoles: ["administrator", "programadmin", "coach"],
          },
        },
        {
          name: "EngagementOverview",
          path: "engagement/:id?/:sessionId?",
          component: () => import("@/views/pages/programs/engagements/EngagementContent"),
          meta: {
            requiresAuth: true,
            title: `${process.env.VUE_APP_NAME} | Viewing Engagement`,
          },
        },
        {
          name: "Program Structure",
          props: {
            pageHeadline: "Program Structure",
          },
          path: "programs/structure/:id/:sessionId?",
          component: () => import("@/views/pages/programs/engagements/EngagementContent"),
          meta: {
            requiresAuth: true,
            title: `${process.env.VUE_APP_NAME} | Viewing Program Structure`,
          },
        },
        {
          name: "Steps",
          path: "steps",
          component: () => import("@/views/pages/programs/engagements/StepsTestSelected"),
          meta: { requiresAuth: true },
        },
        {
          name: "CoachPicker",
          path: "coachpicker",
          component: () => import("@/views/pages/coach/CoachPicker"),
          meta: { requiresAuth: true },
        },
        {
          name: "Meetings",
          path: "meetings",
          component: () => import("@/views/pages/meetings/Meetings"),
          meta: { requiresAuth: true },
        },
        {
          name: "Help",
          path: "help/:section?",
          component: () => import("@/views/pages/help/Help"),
          meta: { requiresAuth: true },
        },
        {
          name: "Settings",
          path: "settings",
          component: () => import("@/views/pages/settings/Settings-User"),
          meta: { requiresAuth: true },
        },
        {
          name: "Announcements",
          path: "/announcements/:program?",
          component: () => import("@/views/pages/coachee/ClientAnnouncements"),
          meta: { requiresAuth: true, allowedRoles: ["client"] },
        },

        // Admin
        {
          name: "Register",
          path: "/register",
          component: () => import("@/views/auth/pages/Register"), // Licensee register
          meta: {
            requiresAuth: true,
            title: `${process.env.VUE_APP_NAME} | Register`,
            allowedRoles: ["superadmin"],
          },
        },
        {
          name: "Licensee Overview",
          path: "/licenseeoverview",
          component: () => import("@/views/pages/admin/LicenseeOverview"),
          meta: {
            requiresAuth: true,
            title: `${process.env.VUE_APP_NAME} | Admin - Licensee Overview`,
            allowedRoles: ["superadmin"],
          },
        },
        {
          name: "Users (Admin)",
          path: "users",
          component: () => import("@/views/pages/admin/Users"),
          meta: {
            requiresAuth: true,
            title: `${process.env.VUE_APP_NAME} | Admin - Users`,
            allowedRoles: ["administrator"],
          },
        },
        {
          name: "Reports",
          path: "reports",
          component: () => import("@/views/pages/admin/reports/ReportOverview"),
          meta: {
            requiresAuth: true,
            title: `${process.env.VUE_APP_NAME} | Admin - Reports`,
            allowedRoles: ["administrator", "programadmin"],
          },
        },
        {
          name: "Organization",
          path: "organization",
          component: () => import("@/views/pages/settings/CompanySettings"),
          meta: {
            requiresAuth: true,
            title: `${process.env.VUE_APP_NAME} | Organization`,
            allowedRoles: ["administrator"],
          },
        },
        {
          name: "Roles",
          path: "roles",
          component: () => import("@/views/pages/admin/Roles"),
          meta: {
            requiresAuth: true,
            title: `${process.env.VUE_APP_NAME} | Admin - Roles`,
          },
        },
      ],
    },
    {
      name: "Login",
      path: "/login",
      component: () => import("@/views/auth/pages/Login"),
    },
    {
      name: "TV Dashboard",
      path: "/tv-dashboard",
      component: () => import("@/views/pages/TvDashboard"),
    },
    {
      name: "ActivateAccount",
      path: "/activate",
      component: () => import("@/views/auth/pages/ActivateAccount"),
    },
    {
      name: "VerifyEmailChange",
      path: "/verifyemailchange",
      component: () => import("@/views/auth/pages/VerifyEmailChange"),
    },
    {
      name: "ResetPassword",
      path: "/resetpassword/:id",
      component: () => import("@/views/auth/pages/password/ResetPassword"),
    },
    {
      name: "ForgotPassword",
      path: "/forgotpassword",
      component: () => import("@/views/auth/pages/password/ForgotPassword"),
    },
    {
      name: "Login2",
      path: "/login2",
      component: () => import("@/views/auth/pages/Login2"),
    },
    {
      name: "404",
      path: "/404",
      component: () => import("@/views/pages/static/404"),
    },
    {
      // Always last, this should probably redirect to a 404 page that we then redirect the user back to the dashboard after a couple seconds.
      name: "Default",
      path: "*",
      redirect: "/404",
    },
  ],
});

router.beforeEach((to, from, next) => {
  const forceTwoFactorSetup =
    store.getters.twoFactorPolicy &&
    (!store.state.authInfo?.login_security ||
      !store.state.authInfo?.login_security?.two_factor_enabled);
  const forceSecondaryEmailSetup =
    !store.state.authInfo.activated_with_sso &&
    store.getters.secondaryEmailPolicy === "required" &&
    (store.state.authInfo.secondary_email === null || store.state.authInfo.secondary_email === "");

  if (to.matched.some((record) => record.meta.requiresAuth) && !store.state.isAuthed) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    cookies.set("flytRedirectTo", to.fullPath, 60 * 10);
    next({ name: "Login" });
  }
  //Forces user to settings page if 2fa is required and
  else if (
    (forceTwoFactorSetup || forceSecondaryEmailSetup) &&
    from.name &&
    to.name !== "Settings"
  ) {
    next({ name: "Settings" });
  } else if (
    to.matched.some((record) => record.meta.allowedRoles) &&
    store.state.authInfo.roles &&
    !store.state.authInfo.roles.some((role) => {
      return to.matched[1].meta.allowedRoles.includes(role.name);
    }) &&
    !(to.matched[1].meta.allowedRoles.includes("superadmin") && store.getters.isSuperadmin)
  ) {
    //Checks if user has one or more of the roles required to access a certain page, otherwise redirect to home
    //Add key to route meta object, usage example: allowedRoles: ["administrator", "coach"]
    store.commit("displaySnackbar", {
      message: "You don't have access to that page, redirecting...",
      color: "error",
      timeout: 3500,
    });
    if (store.getters.isClient) {
      next({ name: "EngagementOverview" });
    } else if (store.getters.isStakeholder) {
      next({ name: "Settings" });
    } else {
      next({ name: "Programs" });
    }
  } else {
    next();
  }
});

const DEFAULT_TITLE = "Flyt Coaching";
// eslint-disable-next-line no-unused-vars
router.afterEach((to, from) => {
  Vue.nextTick(() => {
    document.title = to.meta.title || DEFAULT_TITLE;
  });
});

const originalPush = router.push;
router.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) {
    return originalPush.call(this, location, onResolve, onReject);
  } else {
    return originalPush.call(this, location).catch((err) => {
      if (Router.isNavigationFailure(err)) {
        return err;
      } else {
        return Promise.reject(err);
      }
    });
  }
};

export default router;
