import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import * as constants from "./constants";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    CONSTANTS: constants,
    drawer: true,
    apiUrl: process.env.VUE_APP_API_URL,
    // Since environment variables always get parsed as strings, we do this to parse the value as a boolean
    production: process.env.VUE_APP_PRODUCTION === "true",
    recentProgramfilter: {
      filterSelection: [],
      searchTerm: "",
      sortSelection: "Created (oldest)",
    },
    snackbar: {
      visible: false,
      message: null,
      sessionExpiredMessage: null,
      color: "primary",
      timeout: 5000,
    },
    showCalendar: false,
    calendarEvents: [],
    showTrackingTable: false,
    appLoaded: false,
    isAuthed: false,
    legacyDate: "2021-08-31",
    authInfo: {},
    darkMode: false,
    notifications: [],
    resourceIcons: {
      doc: "/icons/file-types/doc.svg",
      jpg: "/icons/file-types/jpg.svg",
      png: "/icons/file-types/png.svg",
      jpeg: "/icons/file-types/jpg.svg",
      mp4: "/icons/file-types/mp4.svg",
      pdf: "/icons/file-types/pdf.svg",
      txt: "/icons/file-types/txt.svg",
      ppt: "/icons/file-types/ppt.svg",
      xls: "/icons/file-types/xls.svg",
    },
    fileIcons: {
      pdf: "mdi-file-pdf-box",
      png: "mdi-file-image",
      txt: "mdi-file-document-outline",
      xls: "mdi-file-excel",
      doc: "mdi-file-word",
      docx: "mdi-file-word",
      xlsx: "mdi-file-excel",
      jpg: "mdi-file-image",
      jpeg: "mdi-file-image",
      file: "mdi-file",
      mp4: "mdi-movie-open",
      mp3: "mdi-music-note",
      wav: "mdi-music-note",
      m4a: "mdi-music-note",
    },
    fileIconColors: {
      pdf: "red",
      png: "purple",
      txt: "#0149ae",
      xls: "green",
      doc: "#0149ae",
      docx: "#0149ae",
      xlsx: "green",
      jpg: "purple",
      jpeg: "purple",
      file: "yellow",
      mp4: "#0CA4A5",
      mp3: "#0CA4A5",
      wav: "#0CA4A5",
      m4a: "#0CA4A5",
    },
    availableSettings: [
      "userSettingThemePrimary",
      "userSettingThemeSecondary",
      "userSettingThemeAccent",
    ],
    availableRoles: [
      "superadmin",
      "owner",
      "administrator",
      "programadmin",
      "stakeholder",
      "coach",
      "client",
    ],
  },
  getters: {
    branding: (state) => {
      // We hard code to the first index as a user at this point in time can only belong to a single licensee.
      if (state.authInfo.licensee !== undefined) {
        return state.authInfo.licensee[0].settings;
      }
    },
    userRoles: (state) => {
      if (Object.keys(state.authInfo).length === 0 && state.authInfo.constructor === Object)
        return [];

      let roleNames = [];
      state.authInfo.roles.forEach((role) => roleNames.push(role.name));
      return roleNames;
    },
    hasEngagements: (state, getters) => {
      if (state.authInfo === {}) return false;

      return state.authInfo.engagements?.length !== 0;
    },
    isCoach: (state, getters) => {
      if (state.authInfo === {}) return false;
      return getters.userRoles.includes("coach");
    },

    isClient: (state, getters) => {
      if (state.authInfo === {}) return false;
      return getters.userRoles.includes("client");
    },

    isStakeholder: (state, getters) => {
      if (state.authInfo === {}) return false;
      return getters.userRoles.includes("stakeholder");
    },

    isAdmin: (state, getters) => {
      if (state.authInfo === {}) return false;
      return getters.userRoles.includes("administrator");
    },

    isProgramAdmin: (state, getters) => {
      if (state.authInfo === {}) return false;
      return getters.userRoles.includes("programadmin");
    },

    isSuperadmin: (state, getters) => {
      if (state.authInfo === {}) return false;
      return !!state.authInfo.is_superadmin;
    },

    isPrivilegedUser: (state, getters) => {
      if (state.authInfo === {}) return false;
      return getters.isAdmin || getters.isSuperadmin || getters.isCoach || getters.isProgramAdmin;
    },

    isPrivilegedAdmin: (state, getters) => {
      if (state.authInfo === {}) return false;
      return getters.isAdmin || getters.isSuperadmin;
    },

    isAnyAdmin: (state, getters) => {
      if (state.authInfo === {}) return false;
      return getters.isAdmin || getters.isProgramAdmin || getters.isSuperadmin;
    },

    webcalUrl: (state) => {
      return state.apiUrl.replace("https", "webcal");
    },

    storageUrl: () => {
      return process.env.VUE_APP_DO_SS_FULL_URL;
    },

    getP2pSessions: (state) => {
      if (state.authInfo === {}) return [];

      return state.authInfo.step.filter((t) => t.step_type.name === "p2p");
    },

    coachPicker: (state) => {
      if (state.authInfo === {}) return false;

      return (
        state.authInfo.coach_picker !== null && state.authInfo.coach_picker.completed_at === null
      );
    },

    passwordLengthPolicy: (state) => {
      if (
        state.authInfo.licensee &&
        state.authInfo.licensee[0].user_settings.security?.strong_password_policy
      ) {
        return state.CONSTANTS.MAIN.LICENSEE.USER_SETTINGS.PASSWORD_POLICY.STRONG;
      } else {
        return state.CONSTANTS.MAIN.LICENSEE.USER_SETTINGS.PASSWORD_POLICY.WEAK;
      }
    },

    secondaryEmailPolicy: (state) => {
      if (state.authInfo.licensee) {
        return state.authInfo.licensee[0].user_settings.security?.secondary_email_policy;
      }
    },

    twoFactorPolicy: (state) => {
      if (state.authInfo.licensee) {
        return state.authInfo.licensee[0].user_settings.security?.enforced_2fa_policy;
      }
    },

    isDemoLicensee: (state) => {
      if (state.authInfo.licensee) {
        // We specifically check "true" since the attribute is hidden on "false"
        return state.authInfo.licensee[0].is_demo === true;
      }
    },
  },

  mutations: {
    changeDrawer(state) {
      state.drawer = !state.drawer;
    },

    changeProgramFilters: (state, payload) => {
      state.recentProgramfilter.searchTerm = payload.searchTerm;
      state.recentProgramfilter.sortSelection = payload.sortSelection;
      state.recentProgramfilter.filterSelection = payload.filterSelection;
    },

    changeDarkMode(state) {
      localStorage.setItem("darkMode", `${!state.darkMode}`);
      state.darkMode = !state.darkMode;
    },

    changeAuthStatus(state, status) {
      state.isAuthed = status;
    },

    changeLicensee(state, licensee) {
      Vue.set(state.authInfo.licensee, 0, licensee);
    },

    changeAuthInfo(state, authInfo) {
      state.authInfo = authInfo;
      state.appLoaded = true;
    },

    changeTermsAndConditionsFlag(state, value) {
      state.authInfo.has_read_terms = value;
    },

    changeProfileResource(state, payload) {
      state.authInfo.resource = payload.resource;
      state.authInfo.resource_id = payload.resource.id;
    },

    changeProfilePhoneNumber(state, phoneNumber) {
      state.authInfo.phone_number = phoneNumber;
    },

    changeProfileShortBio(state, shortBio) {
      state.authInfo.profile.short_bio = shortBio;
    },

    changeProfileBio(state, bio) {
      state.authInfo.profile.bio = bio;
    },

    changeProfileWebsite(state, website) {
      state.authInfo.profile.website = website;
    },

    changeProfileOccupation(state, occupation) {
      state.authInfo.profile.occupation = occupation;
    },

    changeProfileSecondaryEmail(state, secondaryEmail) {
      state.authInfo.secondaryEmail = secondaryEmail;
    },

    /**
     * when mutating nested state objects, the object needs to be passed alongside the updated value
     * in this case: this.$store.state.authInfo.profile is passed to the store.commit as profile
     */
    changeProfileLanguages(state, { profile, languages }) {
      profile.languages = languages;
    },

    changeProfileCertifications(state, { profile, certificationPayload }) {
      profile.certifications = certificationPayload.certification_array;
    },

    changeProfileSpecialities(state, { profile, specialityPayload }) {
      if (specialityPayload.spliceArray) {
        profile.specialities = profile.specialities.filter(
          (speciality) => speciality.id !== specialityPayload.speciality.id
        );
      } else {
        let uniqueSpecialities = specialityPayload.specialities.filter(
          (payloadSpeciality) =>
            !profile.specialities.some((speciality) => speciality.id === payloadSpeciality.id)
        );
        uniqueSpecialities.forEach((speciality) => profile.specialities.push(speciality));
      }
    },

    changeProfileLocation(state, location) {
      state.authInfo.profile.location = location;
    },

    changeNotifications(state, notifications) {
      state.notifications = notifications;
    },

    deleteNotification(state, notificationId) {
      state.notifications = state.notifications.filter((t) => t.id !== notificationId);
    },

    deleteAllNotifications(state) {
      state.notifications = [];
    },

    displaySessionExpiredSnackbar(state, payload) {
      if (payload?.timeout) state.snackbar.timeout = payload.timeout;

      state.snackbar.visible = true;
      state.snackbar.sessionExpiredMessage = "Session Expired, Redirecting to login...";
      state.snackbar.color = "error";
    },

    displaySnackbar(state, payload) {
      state.snackbar.visible = true;
      if (payload.message) state.snackbar.message = payload.message;

      if (payload.timeout) state.snackbar.timeout = payload.timeout;

      if (payload.color) state.snackbar.color = payload.color;
    },

    toggleCalendar(state, close = false) {
      if (close) {
        state.showCalendar = false;
      } else {
        state.showCalendar = !state.showCalendar;
      }
    },

    //Commit this mutation when creating event to make it show up in calendar. Saves us doing api calls to get all events.
    addCalendarEvent(state, event) {
      state.calendarEvents.push(event);
    },

    updateCalendarEvent(state, event) {
      state.calendarEvents = state.calendarEvents.filter(calendarEvent => {
        return calendarEvent.id !== event.id
      })
      state.calendarEvents.push(event);
    },

    removeCalendarEvent(state, eventId) {
      state.calendarEvents = state.calendarEvents.filter(calendarEvent => {
        return calendarEvent.id !== eventId;
      })
    },

    updateLocationOnEvents(state, payload) {
      state.calendarEvents.forEach(calendarEvent => {
        calendarEvent.locations.forEach(eventLocation => {
          if (eventLocation.id === payload.location.id) {
            eventLocation.name = payload.newName;
          }
        })
      })
    },

    removeLocationFromEvents(state, locationId) {
      state.calendarEvents.forEach(calendarEvent => {
        if (calendarEvent.locations.some(location => location.id === locationId)) {
          calendarEvent.locations = calendarEvent.locations.filter(location => location.id !== locationId)
        }
      })

    },

    toggleTrackingTable(state, close = false) {
      if (close) {
        state.showTrackingTable = false;
      } else {
        state.showTrackingTable = !state.showTrackingTable;
      }
    },
  },
  actions: {
    getAuthInfo({ commit, state }) {
      axios
        .get(`${state.apiUrl}/api/user/authed`)
        .then((response) => {
          commit("changeAuthInfo", response.data);
        })
        .catch();
    },
    async getCalendarEvents({ commit, state }) {
      try {
        let response = await axios.get(`${state.apiUrl}/api/events`);

        state.calendarEvents = response.data;
      } catch (error) {
        commit("displaySnackbar", {
          message: error.response?.data.message ?? error.message,
          color: "error",
          timeout: 4500,
        });
      }
    },
  },
});
