import * as Sentry from "@sentry/vue";

const snackbarHelper = {
  methods: {
    displaySnackbarError(error, message = null, timeout = 4500) {
      // Report any frontend errors to sentry (backend errors already get reported)
      if (error instanceof Error && !error.response) {
        Sentry.captureException(error);
      }
      this.$store.commit("displaySnackbar", {
        message: message ?? error.response?.data.message ?? error.message,
        color: "error",
        timeout: timeout,
      });
    },
    displaySnackbarSuccess(success, message = null, timeout = 4500) {
      this.$store.commit("displaySnackbar", {
        message: message ?? success.data.message,
        color: "success",
        timeout: timeout,
      });
    },
  },
};

export default {
  install(Vue) {
    Vue.mixin(snackbarHelper);
  },
};
