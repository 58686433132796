import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import i18n from "./i18n";
import store from "./store";
import vueDebounce from "vue-debounce";
import VueMoment from "vue-moment";
import moment from "moment-timezone";
import * as Sentry from "@sentry/vue";
import snackbarHelper from "@/mixins/snackbarHelper";
import "material-design-icons-iconfont/dist/material-design-icons.css";
import VueGtag from "vue-gtag";
import Hotjar from "vue-hotjar";
import Shepherd from "shepherd.js";
import axios from "axios";
import createAuthRefreshInterceptor from 'axios-auth-refresh';

Vue.config.productionTip = false;

if (process.env.VUE_APP_SENTRY_DSN) {
  let packageJson = require("/package.json");
  Sentry.init({
    Vue: Vue,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    environment: process.env.VUE_APP_ENVIRONMENT,
    debug: process.env.VUE_APP_PRODUCTION === "false",
    release: `flyt-app@${packageJson.version}`,
  });
}

if (process.env.VUE_APP_PRODUCTION === "true") {
  Vue.use(Hotjar, { id: "2810293" });
  Vue.use(VueGtag, { config: { id: "G-HRS77Z2JJP" } }, router);
}

new Vue({
  vuetify,
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");

Vue.use(vueDebounce);
Vue.use(Shepherd);
Vue.use(snackbarHelper);
Vue.use(VueMoment, {
  moment,
});

Vue.use(require("@/assets/css/custom-shepherd.css"));
Vue.use(require("vue-cookies"));
Vue.$cookies.config(
  process.env.VUE_APP_DEFAULT_COOKIE_EXPIRATION,
  "",
  "",
  process.env.VUE_APP_COOKIES_SECURE,
  "Lax"
);

/**
 * Axios configuration
 *
 * TODO: Consider creating an instance for the flyt API with the baseURL set
 */
axios.defaults.withCredentials = true;

// Gets a new XSRF-TOKEN and retries original request if response status is 419
const getCsrfCookie = (failedRequest) => axios.get(`${store.state.apiUrl}/sanctum/csrf-cookie`).then((response) => Promise.resolve());
createAuthRefreshInterceptor(axios, getCsrfCookie, { statusCodes: [419] });

// For redirecting unauthenticated users back to login
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (
      error.response?.status === 401 &&
      error.response?.config.url !== `${store.state.apiUrl}/api/authed` &&
      router.currentRoute.name !== "Login" &&
      router.currentRoute.name !== "ActivateAccount" &&
      router.currentRoute.name !== "ResetPassword"
    ) {
      store.commit("displaySessionExpiredSnackbar");
      setTimeout(() => {
        router.go();
      }, 5000);
    }
    return Promise.reject(error);
  }
);

